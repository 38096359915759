import { Link } from 'react-router-dom'
import  thumbImg from "../images/thumb.png";
import  cardImg from "../images/card.png";
import  aboutUsImg from "../images/about_us.png";
import  game1Img from "../images/game1.png";
import  game2Img from "../images/game2.png";
import  game3Img from "../images/game3.png";
import  shape2Img from "../images/shape2.png";
import EventCard from "./EventCard";
import Faqs from './Faqs';
import Blogs from './Blogs';
function Main() {
  return (
    <>
    <section className="banner-area">
    <div className="container">
      <div className="b_cnt_wrapper">
        <div className="b_left_cnt">
          <div className="shape-1">
            <img src={cardImg} alt="card" />
          </div>
          <h1>
            THE BEST FREE SOCIAL <span>CASINO GAME</span> Play today.
          </h1>
          <p>
            GoldenMapleSpinsCA.com - your free and entertaining social casino
          </p>
          <div className="b_btn_area">
            <div className="h-btn">
              <Link to="/">
                <i className="fa-regular fa-circle-play"></i>
                Play now
              </Link>
            </div>
            <div className="h-btn">
              <Link to="/">register</Link>
            </div>
          </div>
        </div>
        <div className="b_right_cnt">
          <img src={thumbImg} alt="thumb" />
        </div>
      </div>
    </div>
  </section>
     <section className="about-us-area">
     <div className="container">
       <div className="about_cnt_wrapper">
         <div className="shape-2">
           <img src={cardImg} alt="card" />
         </div>
         <div className="about_right_cnt">
           <div className="about_left_img">
             <img src={aboutUsImg} alt="about us" />
           </div>
           <h2>About the Casino</h2>
           <p>
             The casino is a place where certain gambling games are
             conducted. Casinos are often located near hotels, resorts,
             restaurants, retail stores, cruise ships, and other tourist
             attractions. Some casinos are also known for hosting live
             entertainment such as stand-up comedy, concerts, and sporting
             events.
           </p>
           <div className="h-btn">
             <Link to="/">Contact us</Link>
           </div>
         </div>
         <div className="about_left_cnt">
           <img src={aboutUsImg} alt="about us" />
         </div>
       </div>
     </div>
   </section>
   <section className="top_upcoming-_event">
        <div className="container">
          <div className="section-title">
            <h2>Top Great Games</h2>
            <p>
              Experience the thrill of real-time casino gaming with the live
              streaming feature! Play with real dealers, interact with other
              players, and enjoy an immersive casino experience anytime,
              anywhere.
            </p>
          </div>
          <div className="event_wrapper">
            <EventCard
              to={"/bigmax"}
              image={game1Img}
              title={"Bigmax Diamonds and Wilds Casino Game"}
              description={
                "Experience the excitement of Bigmax Diamonds and Wilds! Spin the reels to chase sparkling diamonds and wild symbols, and win big rewards with bonus rounds, free spins, and jackpots."
              }
            />
            <EventCard
              to={"/king"}
              image={game2Img}
              title={"King of Egypt Casino Game"}
              description={
                "Dive into the ancient world of Egypt with the King of Egypt casino game! Discover lost treasures, spin for big wins, and experience the mystery of the pharaohs with stunning graphics, wilds, and bonus rounds."
              }
            />
            <EventCard
              to={"/fish"}
              image={game3Img}
              title={"Big Fish Casino Game"}
              description={
                "Dive into the depths with the Big Fish casino game! Enjoy a variety of exciting games like slots, poker, and blackjack. Play with friends, win big rewards, and collect bonuses!"
              }
            />
          </div>
        </div>
      </section>
      <section className="why_play_area">
        <div className="container">
          <div className="why_cnt_wrapper">
            <div className="why_cnt_left">
              <h2>Why Play on Our Casino</h2>
              <p>
                The casino is a place where certain gambling games are
                conducted. Casinos are often connected with hotels and resorts.
              </p>
              <p>
                <span>
                  Debitis ad dolor sint consequatur hic, facere est doloribus
                  tempora in laborum similique saepe blanditiis odio nulla
                  repellat dicta reprehenderit. Obcaecati, sed perferendis? Quam
                  cum debitis odit recusandae dolor earum.
                </span>
              </p>
            </div>
            <div className="why_cnt_right">
              <div className="why_cnt_right_inner">
                <i className="fa-solid fa-shield-halved"></i>
                <h3>Safe Casino Games</h3>
                <p>
                  Casino games are common forms of gambling. There are many
                  options in casino games.
                </p>
              </div>
              <div className="why_cnt_right_inner">
                <i className="fa-solid fa-dice-six"></i>
                <h3>Great Gaming Experience</h3>
                <p>
                  Casino games are common forms of gambling. There are many
                  options in casino games.
                </p>
              </div>
              <div className="why_cnt_right_inner">
                <i className="fa-solid fa-trophy"></i>
                <h3>Higher Winning Chances</h3>
                <p>
                  Casino games are common forms of gambling. There are many
                  options in casino games.
                </p>
              </div>
              <div className="why_cnt_right_inner">
                <i className="fa-solid fa-coins"></i>
                <h3>Invest, Win, and Earn</h3>
                <p>
                  Casino games are common forms of gambling. There are many
                  options in casino games.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-3">
          <img src={shape2Img} alt="shape" />
        </div>
      </section>
      <Faqs/>
      <Blogs/>
   </>
  )
}

export default Main
